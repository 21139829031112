import { Link } from "react-router-dom";
import { about_us, contact, getHomeRoute, news, realizations } from "../routes/routes";
import logoGranda from "../assets/logos/granda_white.svg"
import logoGrandaBlue from "../assets/logos/granda_poziom2.svg"
import { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
export default function HeaderPage() {
    
    if (!sessionStorage.getItem("root")) {
        sessionStorage.setItem("root", "/")
    }

    useEffect(() => {
        if (sessionStorage.getItem("root")!.includes("o_nas")) {
            document.getElementById("o_nas")!.className = "HeaderLink active"
        }
        else if (sessionStorage.getItem("root")!.includes("realizacje")) {
            document.getElementById("realizacje")!.className = "HeaderLink active"
        }
        else if (sessionStorage.getItem("root")!.includes("aktualnosci") && window.location.pathname.includes("aktualnosci")) {
            document.getElementById("aktualnosci")!.className = "HeaderLink active"
        }
        else if (sessionStorage.getItem("root")!.includes("kontakt")) {
            document.getElementById("kontakt")!.className = "HeaderLink active"
        }
    })

    const colorChange = (e: any) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        sessionStorage.setItem("root", e.currentTarget.id)
        const a = document.getElementsByClassName("HeaderLink")
        Array.prototype.forEach.call(a, (el) => {
            el.className = "HeaderLink"
        })
    }
    return (
        <header>
            <div className="headerDesktop">
                <Link to={about_us()} className="HeaderLink" id="o_nas" onClick={(e) => { colorChange(e) }}>O nas</Link>
                <Link to={realizations()} className="HeaderLink" id="realizacje" onClick={(e) => { colorChange(e) }}>Realizacje</Link>
                <Link to={getHomeRoute()} className="logoHeaderLink" id="/" onClick={(e) => { colorChange(e) }}><img src={logoGranda} alt="" className="logoHeader" /></Link>
                <Link to={news()} className="HeaderLink" id="aktualnosci" onClick={(e) => { colorChange(e) }}>Aktualności</Link>
                <Link to={contact()} className="HeaderLink" id="kontakt" onClick={(e) => { colorChange(e) }}>Kontakt</Link>
            </div>

            <div className="headerMobile">
                {[false].map((expand) => (
                    <Navbar expand={expand} className="bg-body-tertiary">
                        <Container fluid style={{flexWrap: "nowrap"}}>
                            <Navbar.Brand href="/"><img src={logoGrandaBlue} alt="" /></Navbar.Brand>
                            <Navbar.Toggle />
                            <Navbar.Offcanvas placement="end">
                                <Offcanvas.Header closeButton closeVariant="white">

                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                        <Navbar.Toggle><Link to={getHomeRoute()} className="logoHeaderLink" id="/" onClick={(e: any) => { colorChange(e) }}>Strona główna</Link></Navbar.Toggle>
                                        <Navbar.Toggle><Link to={about_us()} className="HeaderLink" id="o_nas" onClick={(e) => { colorChange(e) }}>O nas</Link> </Navbar.Toggle>
                                        <Navbar.Toggle><Link to={realizations()} className="HeaderLink" id="realizacje" onClick={(e) => { colorChange(e) }}>Realizacje</Link> </Navbar.Toggle>

                                        <Navbar.Toggle><Link to={news()} className="HeaderLink" id="aktualnosci" onClick={(e) => { colorChange(e) }}>Aktualności</Link></Navbar.Toggle>
                                        <Navbar.Toggle><Link to={contact()} className="HeaderLink" id="kontakt" onClick={(e) => { colorChange(e) }}>Kontakt</Link></Navbar.Toggle>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar >
                ))}
            </div>
        </header>
    )
}