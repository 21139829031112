export function about_us() {
    return '/o_nas'
}
export function realizations() {
    return '/realizacje'
}
export function contact() {
    return '/kontakt'
}
export function news() {
    return '/aktualnosci'
}
export function getHomeRoute(){
    return '/'
}

export function boardRoute(){
    return '/realizacje/gry_planszowe_i_paragrafowe'}
export function integrationRoute(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe'
}
export function terrainRoute(){
    return '/realizacje/gry_multimedialne_i_terenowe'
}
export function exhobitionRoute(){
    return '/realizacje/gry_okolicznościowe_i_wystawowe'
}

//integracyjne

export function rynek(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/rynek_przyszlosci'
}
export function startUp(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/start-up_fest'
}
export function reconstruction(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/rekonstrukcja'
}
export function rakieta(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/rakieta_zespolowa'
}
export function sensore(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/sensore_hex'
}
export function escape(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/escape_box'
}
export function barwy(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/barwy_wspolne'
}
export function archipelag(){
    return '/realizacje/gry_integracyjne_i_szkoleniowe/archipelag'
}





//multimedialne
export function niezasmiecam(){
    return '/realizacje/gry_multimedialne_i_terenowe/nie_zasmiecam_przyrody'
}
export function ekohakaton(){
    return '/realizacje/gry_multimedialne_i_terenowe/ekohakaton'
}
export function lowcy(){
    return '/realizacje/gry_multimedialne_i_terenowe/lowcy_cieni'
}

export function park(){
    return '/realizacje/gry_multimedialne_i_terenowe/park_rzezby'
}
export function safety(){
    return '/realizacje/gry_multimedialne_i_terenowe/safety_master'
}
export function slonim(){
    return '/realizacje/gry_multimedialne_i_terenowe/slonimski'
}
export function natropie(){
    return '/realizacje/gry_multimedialne_i_terenowe/na_tropie'
}
export function sokolnicki(){
    return '/realizacje/gry_multimedialne_i_terenowe/misja_sokolnicki'
}
//planszowe i paragrafowe

export function proba(){
    return '/realizacje/gry_planszowe_i_paragrafowe/proba_ognia'
}
export function opn(){
    return '/realizacje/gry_planszowe_i_paragrafowe/wyprawa_przyrodnikow'
}
export function miastoZmian(){
    return '/realizacje/gry_planszowe_i_paragrafowe/miasto_zmian'
}

export function pugilares(){
    return '/realizacje/gry_planszowe_i_paragrafowe/tajemnica_pugilaresu'
}

//okolicznościowe i wystawowe


export function wyspy(){
    return '/realizacje/gry_okolicznościowe_i_wystawowe/wyspy_archipelagu_empatii'
}
export function mhp(){
    return '/realizacje/gry_okolicznościowe_i_wystawowe/festiwal_otwarcia_mhp'
}
export function dekoder(){
    return '/realizacje/gry_okolicznościowe_i_wystawowe/dekoder'
}
export function zlecenie(){
    return '/realizacje/gry_okolicznościowe_i_wystawowe/masz_zlecenie'
}


export function getTestRoute(){
    return '/Test'
}