// import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style.scss';
import React, { Suspense } from 'react';
import { about_us, contact, news, realizations, getTestRoute, terrainRoute, boardRoute, exhobitionRoute, integrationRoute, reconstruction, niezasmiecam, lowcy, ekohakaton, park, safety, slonim, natropie, sokolnicki, archipelag, barwy, escape, rakieta, sensore, rynek, startUp, proba, opn, miastoZmian, pugilares, zlecenie, dekoder, mhp, wyspy } from './routes/routes';
import HeaderPage from './layout/header';
import Cookies from './components/cookies';



const AboutUs = React.lazy(()=> import('./routes/about'))
const HomePage = React.lazy(()=> import('./routes/home'))
const Realization = React.lazy(()=> import('./routes/realizations'))
const Contact = React.lazy(()=> import('./routes/contact'))
const News = React.lazy(()=> import('./routes/news'))
const Test = React.lazy(()=> import('./routes/test'))
const Exhibition = React.lazy(()=>import('./routes/realizations_pages/exhibitions/index'))
const Terrain = React.lazy(()=>import('./routes/realizations_pages/terrain/index'))
const Board = React.lazy(()=>import('./routes/realizations_pages/boardGames/index'))
const Integrations = React.lazy(()=>import('./routes/realizations_pages/integrations/index'))

//multimedialne i terenowe
const Przyrody = React.lazy(()=>import('./routes/realizations_pages/terrain/przyrody'))
const Ekohakaton = React.lazy(()=>import('./routes/realizations_pages/terrain/ekohakaton'))
const Lowcy = React.lazy(()=>import('./routes/realizations_pages/terrain/lowcy'))
const Park = React.lazy(()=>import('./routes/realizations_pages/terrain/park'))
const Safety = React.lazy(()=>import('./routes/realizations_pages/terrain/safety'))
const Slonimski = React.lazy(()=>import('./routes/realizations_pages/terrain/slonimski'))
const NaTropie = React.lazy(()=>import('./routes/realizations_pages/terrain/natropie'))
const Sokolnicki = React.lazy(()=>import('./routes/realizations_pages/terrain/sokolnicki'))

// integracyjne i szkoleniowe

const Archipelag = React.lazy(()=>import('./routes/realizations_pages/integrations/archipelag'))
const Barwy = React.lazy(()=>import('./routes/realizations_pages/integrations/barwy'))
const Escape = React.lazy(()=>import('./routes/realizations_pages/integrations/escape'))
const Rakieta = React.lazy(()=>import('./routes/realizations_pages/integrations/rakieta'))
const Sensore = React.lazy(()=>import('./routes/realizations_pages/integrations/sensore'))
const Rekonstrukcja = React.lazy(()=>import('./routes/realizations_pages/integrations/rekonstrukcja'))
const Rynek = React.lazy(()=>import('./routes/realizations_pages/integrations/rynek'))
const StartUp = React.lazy(()=>import('./routes/realizations_pages/integrations/startUp'))

//planszowe i promocyjne


const Proba = React.lazy(()=>import('./routes/realizations_pages/boardGames/proba'))
const Opn = React.lazy(()=>import('./routes/realizations_pages/boardGames/opn'))
const Miasto = React.lazy(()=>import('./routes/realizations_pages/boardGames/miasto'))
const Pugilares = React.lazy(()=>import('./routes/realizations_pages/boardGames/pugilares'))


//wystawowe i okolicznościowe

const Wyspa = React.lazy(()=>import('./routes/realizations_pages/exhibitions/wyspa'))
const MHP = React.lazy(()=>import('./routes/realizations_pages/exhibitions/mhp'))
const Dekoder = React.lazy(()=>import('./routes/realizations_pages/exhibitions/dekoder'))
const Zlecenie = React.lazy(()=>import('./routes/realizations_pages/exhibitions/zlecenie'))

function App() {
  
  return (
    <>
    <BrowserRouter>
      <Suspense fallback={<div>Trwa ładowanie strony</div>}>
        <HeaderPage />
        <Routes>
          <Route path={'/'} element={<HomePage />}/>
          <Route path={about_us()} element={<AboutUs />}/>
          
          <Route path={realizations()} element={<Realization />}/>
          <Route path={contact()} element={<Contact />}/>
          <Route path={news()} element={<News />}/>
          <Route path={getTestRoute()} element={<Test />}/>

          <Route path={terrainRoute()} element={<Terrain />}/>
          <Route path={exhobitionRoute()} element={<Exhibition />}/>
          <Route path={boardRoute()} element={<Board />}/>
          <Route path={integrationRoute()} element={<Integrations />}/>


          {/* more info pages */}
          {/* multimedialne i terenowe */}
          <Route path={niezasmiecam()} element={<Przyrody />} />
          <Route path={ekohakaton()} element={<Ekohakaton />} />
          <Route path={lowcy()} element={<Lowcy />} />
          <Route path={park()} element={<Park />} />

          <Route path={safety()} element={<Safety />} />
          <Route path={slonim()} element={<Slonimski />} />
          <Route path={natropie()} element={<NaTropie />} />
          <Route path={sokolnicki()} element={<Sokolnicki />} />


          {/* integracyjne i szkoleniowe */}
          <Route path={archipelag()} element={<Archipelag />} />
          <Route path={barwy()} element={<Barwy />} />
          <Route path={escape()} element={<Escape />} />
          <Route path={rakieta()} element={<Rakieta />} />

          <Route path={sensore()} element={<Sensore />} />
          <Route path={reconstruction()} element={<Rekonstrukcja />} />
          <Route path={rynek()} element={<Rynek />} />
          <Route path={startUp()} element={<StartUp />} />

          {/* planszowe i promocyjne  */}

          <Route path={proba()} element={<Proba />} />
          <Route path={opn()} element={<Opn />} />
          <Route path={miastoZmian()} element={<Miasto />} />
          <Route path={pugilares()} element={<Pugilares />} />

          {/* okolicznościowe i wystawowe */}
          <Route path={wyspy()} element={<Wyspa />} />
          <Route path={mhp()} element={<MHP />} />
          <Route path={dekoder()} element={<Dekoder />} />
          <Route path={zlecenie()} element={<Zlecenie />} />
        </Routes>
        {/* <FooterPage /> */}
        <Cookies />
      </Suspense>
    </BrowserRouter>
    
    </>
  );
}

export default App;
