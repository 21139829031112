import { useState } from "react"
import style from "./style.module.scss"
import cookie from "../../assets/icons/cookies.svg"
export default function Cookies() {
    const [show, setShow] = useState(true)
    return (<>
        {!document.cookie && show &&
            <div className={style.cookiesInfo}>
                <img src={cookie} alt="" />
                <div>
                    <p>Ta strona używa plików cookie. Przechodząc dalej zgadasz się na ich wykorzystanie</p>
                    <div className={style.buttons}>
                        <button type="button" onClick={() => { document.cookie = "true"; setShow(false) }}>zaakceptuj</button>
                        <a href="./policy.pdf" target="blank">polityka prywatności</a>
                    </div>
                </div>
            </div>}
    </>
    )
}